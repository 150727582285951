$theme_variation: fineart;
@import "~bulmatown/frontend/styles";

.cover_image {
    width: 170px;
    height: 170px;
    object-fit: cover;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 0.5rem;
}

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif&family=Nunito+Sans:wght@400;600&display=swap');

.va {
  display: flex;
  align-items: center;
}

html {
  font-family: 'Nunito Sans', sans-serif;
  color: white;
}

a {
  color: #3b7adf;
}

a:hover {
  text-decoration: underline;
  color: #3b7adf;
}

hr {
  background: #24272c;
}

.hero {
  background: #060606;
}

.title,
.subtitle {
  color: white;
}

.navbar .navbar-item {
  color: #e4e2ff;
}

.navbar .navbar-item.is-active {
  color: #e4e2ff;
  font-weight: 700;
}

.navbar .navbar-item:hover {
  background: transparent;
  color: white;
}

.navbar .navbar-item a {
  margin-left: 1rem;
  color: #e4e2ff;
}

.blog-posts {
  min-height: 100vh;
  background: #191b1f;
  padding-top: 4rem;
  padding-bottom: 10rem;
}

.blog-posts article {
  padding: 1.5rem;
}

.blog-posts .post img {
  border-radius: 6px;
}

.blog-posts .post .post-category {
  color: #3b7adf;
}

.blog-posts .post .post-title {
  font-size: 2.2rem;
}

.blog-posts .post .post-excerpt {
  line-height: 1.5rem;
  letter-spacing: .03rem;
  font-size: 1.2rem;
  font-family: 'IBM Plex Serif', serif;
  color: #92a3ab;
}

.blog-posts .post .featured .featured-image img {
  height: 380px;
}
    ::-webkit-scrollbar{height:10px;width:10px}::-webkit-scrollbar-track{background:#efefef;border-radius:6px}::-webkit-scrollbar-thumb{background:#d5d5d5;border-radius:6px}::-webkit-scrollbar-thumb:hover{background:#c4c4c4}